@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.footer {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    align-items: center;
    position: absolute;
    bottom: 1.5rem;
    height: 2.5rem;
    width: 100%;
    @include tablet {
        padding: 2rem 4rem;
    }
    @include desktop {
        padding: 3rem 6rem;
    }
    &__txt {
        font-size: 0.7rem;
        color: $dark-color;
        @include desktop {
            font-size: 1rem;
        }
    }
    &__icon-box {
        width: 30%;
        display: flex;
        justify-content: space-between;
        @include tablet {
            width: 20%;
        }
        @include desktop {
            width: 15%;
        }
        
    }
    &__icon {
        width: 40px;
        height: 40px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        @include desktop {
            width: 55px;
            height: 55px;   
        }
    }
}