@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-height: 31.25rem;
    padding: 0 2rem;
    margin-top: 2.5rem;
    @include tablet {
        max-height: 45rem;
        padding: 0 4rem;
    }
    @include desktop {
        max-height: 55rem;
    }
    &__title {
        margin: 0;
        letter-spacing: 6px;
        text-align: center;
        padding-bottom: 3rem;
        @include tablet {
            font-size: 3rem;
            letter-spacing: 8px;
            padding-bottom: 4rem;

        }
        @include desktop {
            font-size: 4rem; 
            letter-spacing: 14px;
        }
    }
    &__sub {
        margin-top: 0.5rem;
        margin-bottom: 3rem;
        font-size: 0.8rem;
        font-weight: 300;
        @include tablet {
            margin-bottom: 4rem;
            font-size: 1rem;
        }
    }
    &__text {
        text-align: center;
        margin: 0 0 3rem 0;
        @include tablet {
            margin-bottom: 4rem;
            padding: 0 6rem;
        }
    }
    &__button {
        @include button-light;
        font-weight: normal;
        &:hover {
            background-color: $dark-color;
        }
    }
}