@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.skills {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    @include tablet {
        padding: 0 4rem;
    }
    @include desktop {
        padding: 0 15rem;
    }
    &__title {
        @include section-title;
    }
    &__border {
        @include small-border;
    }
    &__tech-box {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin-top: 2rem;
    }
    &__tech {
        @include button-dark;
        width: 40%;
        font-size: .75rem;
        margin: 0.5rem;
        background-color: rgba(66, 66, 66, 0.6);
        box-shadow: none;
        @include tablet {
            width: 30%;
            font-size: 0.8rem;
        }
        @include desktop {
            font-size: 1rem;
        }
    }
}