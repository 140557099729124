@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.form {
  padding: 0 2rem;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  @include tablet {
    padding: 0 4rem;
    padding-top: 4.5rem;
    margin-top: 5rem;
  }
  @include desktop {
    margin-top: 10rem;
    padding: 0 20rem;
    padding-top: 8rem;
  }
  &__title {
    @include section-title;
    align-self: center;
  }
  &__border {
    @include small-border;
    align-self: center;
  }
  &__text {
    text-align: center;
    margin: 3rem 0 1rem 0;
  }
  &__label {
    padding: 1rem 0;
    font-weight: bold;
    letter-spacing: 1px;
  }
  &__input {
    outline: none;
    resize: none;
    font-size: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
    font-family: "lato";
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    font-weight: 300;
    &:focus {
      border: 1px solid $dark-color;
    }
    &--invalid {
      border: 1px solid $light-color;
      margin-bottom: 0;
    }
  }
  &__btn {
    @include button-dark;
    margin-top: 2rem;
    font-size: 1rem;
    width: 200px;
    align-self: center;
    &:hover {
      background-color: $light-color;
      cursor: pointer;
    }
  }
  &__invalid {
    color: $light-color;
    font-size: 0.7rem;
    margin: 0;
  }
}
