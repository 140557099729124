// fonts
@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-BoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/Lato-BoldItalic.woff') format('woff'),
        url('../../assets/fonts/Lato-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-LightItalic.woff2') format('woff2'),
        url('../../assets/fonts/Lato-LightItalic.woff') format('woff'),
        url('../../assets/fonts/Lato-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-Light.woff2') format('woff2'),
        url('../../assets/fonts/Lato-Light.woff') format('woff'),
        url('../../assets/fonts/Lato-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-Italic.woff2') format('woff2'),
        url('../../assets/fonts/Lato-Italic.woff') format('woff'),
        url('../../assets/fonts/Lato-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-Black.woff2') format('woff2'),
        url('../../assets/fonts/Lato-Black.woff') format('woff'),
        url('../../assets/fonts/Lato-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Lato-Regular.woff') format('woff'),
        url('../../assets/fonts/Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}