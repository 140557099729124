@use './styles/partials/global' as *;
@use './styles/partials/variables' as *;
@use './styles/partials/mixins' as *;

.body {
    &__container{
        position: relative;
        min-height: 100vh;
    }
    &__wrap {
        padding-bottom: 5rem;
        @include tablet {
            padding-bottom: 20rem;
        }
    }
}