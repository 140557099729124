@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    padding: 0 3rem 3rem 3rem;
    @include tablet {
        padding: 0 4rem;
    }
    @include desktop {
        margin-top: 10rem;
        padding: 0 20rem; 
    }
    &__title {
        @include section-title;
    }
    &__border {
        @include small-border;
    }
    &__img {
        margin-top: 3rem;
        width: 100%;
        margin-bottom: 2rem;
        
        @include tablet {
            width: auto;
            max-height: 500px;
            margin-bottom: 4rem;
        }
        @include desktop {
            max-height: 700px;
            margin-bottom: 5rem;
        }
    }
    &__demo {
        width: 100%;
        margin-bottom: 2rem;
        margin-top: 3rem;
        @include tablet {
            height: 25rem;
            margin-bottom: 4rem;
        }
        @include desktop {
            width: 56.25rem;
            height: 37.5rem;
            margin-bottom: 5rem;
        }
    }
    &__btn {
        @include button-dark;
        width: 50%;
        max-width: 15rem;
        @include tablet {
            margin-bottom: 1rem;
        }
        @include desktop {
            margin-bottom: 2rem;
            font-size: 1.5rem;
        }
        &:hover {
            background-color: $light-color;
        }
    }
    &__sub {
        margin: 0;
        margin-top: 1rem;
        padding: 1rem 0;
        @include desktop {
            font-size: 1.8rem;
        }
    }
    &__txt {
        text-align: center;    
        @include desktop {
            font-size: 1.5rem;
            margin-bottom: 3rem;
        }
        &--link {
            color: $dark-color;
        }
    }
    &__skills {
        display: flex;
        justify-content:space-between;
        flex-wrap: wrap;
        width: 100%;
    }
    &__tech {
        @include button-dark;
        width: 30%;
        font-size: .75rem;
        margin: 1rem 0;
        background-color: rgba(66, 66, 66, 0.6);
        box-shadow: none;
        @include tablet {
            width: 28%;
            font-size: 1rem;
        }
        @include desktop {
            font-size: 1.2rem;
        }
    }
    &__back-btn {
        background-color: $background-color;
        border: 1px solid $dark-color;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        padding: 0.5rem 1rem;
        cursor: pointer;
        margin-top: 2rem;
        &:hover {
            box-shadow: 0px 10px 13px -7px #000000, 1px 5px 22px 6px rgba(0,0,0,0);
        }
    }
}