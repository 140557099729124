@use './variables' as *;

@mixin tablet {
    @media (min-width:$breakpoint-tablet) {
        @content;
    }
}

@mixin desktop {
    @media (min-width:$breakpoint-desktop) {
        @content;
    }
}

@mixin button-light {
    background-color: $light-color;
    color: $background-color;
    padding: 1rem 3rem;
    border-radius: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 1rem;
    font-weight: normal;
    &:hover {
        box-shadow: 0px 10px 13px -7px #000000, 1px 5px 22px 6px rgba(0,0,0,0);
    }
}

@mixin button-dark {
    background-color: $dark-color;
    color: $background-color;
    padding: 1rem;
    border-radius: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-align: center;
    &:hover {
        box-shadow: 0px 10px 13px -7px #000000, 1px 5px 22px 6px rgba(0,0,0,0);
    }
}

@mixin small-border {
    border: 2px solid $light-color;
    width: 1.875rem;
}

@mixin section-title {
    margin: 0;
    padding-bottom: 1rem;
    letter-spacing: 6px;
    @include desktop {
        font-size: 2rem;
    }
}
