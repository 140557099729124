@use './variables' as *;
@use './typography' as *;
@use './mixins' as *;

*, *::before, *::after{
	box-sizing: border-box;
}
body {
    font-family: 'Lato';
    font-weight: 400;
    margin: 0;
    color: $dark-color;
    background-color: $background-color;
}
.link {
    text-decoration: none;
}
html {
    scroll-behavior: smooth;

}