@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.header {
    background-color: $background-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    width: 100%;
    position: fixed;
    top:0; 
    @include tablet {
        padding: 1rem 3rem;
    }
    @include desktop {
        padding: 2rem 4rem;
    }
    &__logo-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__logo {
        margin-left: 1rem;
        width: 2.5rem;
        height: 2.5rem;
        @include desktop {
            width: 4rem;
            height: 4rem;
        }
    }
    &__nav-item {
        color: $dark-color;
        margin: 0 1rem;
        padding-bottom: 0.5rem;
        font-size: 1rem;
        font-weight: bold;
        font-style: italic;
        @include desktop {
            font-size: 1.5rem;
        }
        &:hover {
            border-bottom: 1px solid $light-color;
        }
    }
}